// [][][][][][][][][][][][][][][][][][][][][ START IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][]
// ####################### GLOBAL ############################################################################
// Import from React
// eslint-disable-next-line
import { useState, useEffect, useRef } from 'react'

// Import Toast
// eslint-disable-next-line
import { toast } from 'react-toastify'

// Import Auth
import { useAuth } from '../../middleware/contextHooks'

// Import reactDOM
import { useNavigate } from 'react-router-dom'

// Import utils
//import { AuthCheck } from '../../middleware/utils'
// ####################### END OF GLOBAL #####################################################################

// ####################### SPECIFIC ##########################################################################
// Import globalStyles elements
//import { /*CustomContainer as Container*/ } from '../../themes/Darwinian2023/skeleton/global'

// Import Setting
import { useSetting } from '../../middleware/contextHooks'

// Import Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

// Import from MUI
import {
    //Container,
    InputAdornment,
} from '@mui/material'

// Import Addons
import { Spacer } from '../../components/Addon/Spacer'

// Import utils
import { settingLookup } from '../../middleware/utils'

// Animation
import { MotionAnimate } from 'react-motion-animate'
// ####################### END OF SPECIFIC ##################################################################
// [][][][][][][][][][][][][][][][][][][][][ END IMPORTS ][][][][][][][][][][][][][][][][][][][][][][][][][][][][]

export default function Register (props) { 
    // [][][][][][][][][][][][][][][][][][][][][ START SETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][]
    // ####################### GLOBAL ############################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    // Get user logged
    // eslint-disable-next-line
    const {
        // Variables
        isAuthenticated,
        registerUser,
        RegisterFormIsDisabled,

        // Functions
        setIsRegistering,
        setRegisterFormIsDisabled
    } = useAuth()   

    // Set navigate
    const navigate = useNavigate()

    // #region -----------------[ State Variables ]---------------------------------------------------------------
    
    // #end region -----------------[ State Variables ]-----------------------------------------------------------
    // #endregion ------------------------------------------------------------------------------------------------
    // #region --------------( FUNCTIONS )------------------------------------------------------------------------
    
    // #endregion ------------------------------------------------------------------------------------------------
    // ####################### END OF GLOBAL #####################################################################

    // ####################### SPECIFIC ##########################################################################
    // #region -------------[ Variables ]-------------------------------------------------------------------------
    
    const {
        // Variables
        variables,

    } = useSetting()

    // Set default user
    const [user, setUser] = useState({
        alphaCode: '',
        firstName: '',
        lastName: '',
        userName: '', 
        phone: '',
        displayName: '',
        email: '',
        password: '', 
        confirmPassword: '',         
        
        /*
        alphaCode: 'TESTER2024',
        firstName: 'John',
        lastName: 'Titor',
        userName: 'jtitor', 
        phone: '+1 222 239 4434',
        displayName: 'Titor',
        email: 'foxcorpofficial@gmail.com',
        password: 'password', 
        confirmPassword: 'password',             
        */
    })

    // Set showPassword
    const [showPassword, setShowPassword] = useState(false)

    // Set RegisterSent
    //const [ RegisterSent, setRegisterSent ] = useState(false)

    // eslint-disable-next-line
    const [ phone, setPhone ] = useState('')

    // Set skipStory for controlling to go to form and skip story
    const [ skipStory, setSkipStory ] = useState(true)

    // Set up to open story
    const [ storyOpened, setStoryOpened ] = useState(false)

    // ----- States for frames ----------------------------------------------------------------------------
    // Frame 1
    const [ finishedFrame1, setFinishedFrame1 ] = useState(false)

    // Frame 2
    const [ finishedFrame2, setFinishedFrame2 ] = useState(false)

    // Frame 3
    const [ finishedFrame3, setFinishedFrame3 ] = useState(false)

    // Frame 4
    const [ finishedFrame4, setFinishedFrame4 ] = useState(false)

    // Frame 5
    const [ finishedFrame5, setFinishedFrame5 ] = useState(false)
    const [ hasSetName, setHasSetName ] = useState(false)
    const [ learnedAIName, setLearnedAIName ] = useState(false)

    // Frame 6
    const [ finishedFrame6, setFinishedFrame6 ] = useState(false)

    // Frame 7
    const [ finishedFrame7, setFinishedFrame7 ] = useState(false)

    // Frame 8
    const [ finishedFrame8, setFinishedFrame8 ] = useState(false)
    const [ askedQuestionUsername, setAskedQuestionUsername ] = useState(false)
    const [ hasSetUserName, setHasSetUserName ] = useState(false)

    // Frame 9
    const [ finishedFrame9, setFinishedFrame9 ] = useState(false)
    // ----------------------------------------------------------------------------------------------------

    const [ scrollPositionState, setScrollPositionState ] = useState(0)

    // #endregion -----------------------------------------------------------------------------------------------
    // #region -------------[ Functions ]------------------------------------------------------------------------    
    // Handle Go to Dashboard
    const handleGotoDashboard = () => {
        navigate("/dashboard")
    }
    
    // Handle Register
    const handleRegister = () => {
        const { 
            alphaCode, betaCode, firstName, lastName, userName, email, password, phone, confirmPassword 
        } = user
        if(!firstName || !lastName || !userName || !email || !password || !phone || !confirmPassword) {
            toast('Please fill all the fields', {type: 'error'})
            return
        }

        if (settingLookup('systemMode', variables) === "alpha") {
            if(!alphaCode) {
                toast('During this time, we require an Alpha Code', {type: 'error'})
                return
            }
        }
        
        if (settingLookup('systemMode', variables) === "beta") {
            if (settingLookup('requireBetaCodeOnRegister', variables) === "yes") {
                if(!betaCode) {
                    toast('During this time, we require an Beta Code', {type: 'error'})
                    return
                }
            }
            
        }

        if (settingLookup('systemMode', variables) === "closed") {
            toast('Registration is closed.', {type: 'error'})
                return
            
        }

        if(password !== confirmPassword) {
            toast('Passwords do not match', {type: 'error'})
            return
        }

        // Set isDisabled (disable form)
        setRegisterFormIsDisabled(true)

        // Set Registering 
        setIsRegistering(true)

        // Register the user
        registerUser(user)          

        // DEPRECATED: Version <2.0.3 - Don't need to set Authed state as we aren't logging the user in; gotta email verify first. 
        // Auth them - this helps to check for on the page being authed
        //setAuthed(true)
    }

    // Handle for scrolling
    const handleScroll = () => {
        const scrollPosition = window.scrollY

        //console.log(scrollPosition)

        setScrollPositionState(scrollPosition)
    }

    // Handle for skipping story
    const handleSkipStory = () => {
        //toast.info(`WIP: Skip the story`, {theme: 'light'})
        setSkipStory(true)
    }

    // ----- Handles for frames ---------------------------------------------------------------------------
    // --- Frame 1 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 2 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 3 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 4 ----------------------------------------------------------------------------------------
    // Handle SetName
    const handleSetName = () => {
        if (user.firstName !== "" && user.lastName !== "") {
            setHasSetName(true)
        } else {
            toast.error('Please tell me your name', { theme: 'light' })
        }
    }
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 5 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 6 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 7 ----------------------------------------------------------------------------------------
    
    // ----------------------------------------------------------------------------------------------------
    // --- Frame 8 ----------------------------------------------------------------------------------------
    const handleSetUserName = () => {
        if (user.userName !== "") {
            setHasSetUserName(true)
        } else {
            toast.error('Please tell me another name.', { theme: 'light' })
        }
    }
    // ----------------------------------------------------------------------------------------------------
    // ----------------------------------------------------------------------------------------------------

    const handleTelChange = (newPhone) => {
        setPhone(newPhone)

        // Set the user state
        setUser({...user, phone: newPhone})
    }

    // State management for handling scrolling
    useEffect(() => {
        handleScroll()

        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    // State management for debugging
    useEffect(() => {
        //if (finshedFrame1) console.log(finshedFrame1)
    }, [ 
        //finshedFrame1
     ])
    
    
    // #endregion -----------------------------------------------------------------------------------------------
    // ####################### END OF SPECIFIC ##################################################################
    // [][][][][][][][][][][][][][][][][][][][][ ENDSETUP ][][][][][][][][][][][][][][][][][][][][][][][][][][][]

    

    // Build frames for story
    const frames = [
        /* TEMPLATE
        
        */
        {/* FRAME 1 - Chapter 1: Beginning */
            id: 1,
            description: "FRAME 1 - Beginning",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to be set
            ],
            scrollReq: 0,
            dialog: [
                {
                    id: 1,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 0,
                        speed: 1,
                        reset: false,
                    },
                    text: "..."
                },
                {
                    id: 2,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to begin)"
                },
                {
                    id: 3,
                    type: "setter",
                    delay: 4,
                    required: [
                        
                    ],
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame1",
                            variable: finishedFrame1,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame1(value)                                
                                    console.log("changed finishedFrame1 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        {/* FRAME 2 - Hello Traveler! */
            id: 2,
            description: "FRAME 2 - Hello Traveler!",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set
                {
                    id: 1,
                    name: "finishedframe1",
                    variableValue: finishedFrame1
                }
            ],
            scrollReq: 900,
            dialog: [
                {
                    id: 1,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "Hello Traveler!"
                },
                {
                    id: 2,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 6,
                        speed: 2,
                        reset: false,
                    },
                    text: "You've been asleep a long time"
                },
                {
                    id: 3,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 9,
                        speed: 1.5,
                        reset: false,
                    },
                    text: "You may be wondering - what is going on?"
                },
                {
                    id: 4,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 10,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 5,
                    delay: 11,
                    required: [
                        
                    ],
                    type: "setter",
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame2",
                            variable: finishedFrame2,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame2(value)                                
                                    console.log("changed finishedFrame2 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        {/* FRAME 3 - Introduction */
            id: 3,
            description: "FRAME 3 - Introduction",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set
                {
                    id: 1,
                    name: "finishedframe1",
                    variableValue: finishedFrame1
                },
                {
                    id: 2,
                    name: "finishedframe2",
                    variableValue: finishedFrame2
                }
            ],
            scrollReq: 1800,
            dialog: [
                {
                    id: 1,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "You just appeared out of thin air."
                },
                {
                    id: 2,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 6,
                        speed: 1,
                        reset: false,
                    },
                    text: "Do you know where you are?"
                },
                {
                    id: 3,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 12,
                        speed: 1,
                        reset: false,
                    },
                    text: "Hello?"
                },
                {
                    id: 4,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 18,
                        speed: 2,
                        reset: false,
                    },
                    text: "Not much of a talker eh?"
                },
                {
                    id: 5,
                    type: "text",
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 19,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 6,
                    type: "setter",
                    delay: 20,
                    required: [
                        
                    ],
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame3",
                            variable: finishedFrame3,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame3(value)                                
                                    console.log("changed finishedFrame3 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        {/* FRAME 4 - Introduction (con't) */
            id: 4,
            description: "FRAME 4 - Introduction (con't)",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedframe1",
                    variableValue: finishedFrame1
                },
                {
                    id: 2,
                    name: "finishedframe2",
                    variableValue: finishedFrame2
                },
                {
                    id: 3,
                    name: "finishedframe3",
                    variableValue: finishedFrame3
                },
            ],
            scrollReq: 2700,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "No worries. Fortunately for you, I am able to read your thoughts."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 8,
                        speed: 1,
                        reset: false,
                    },
                    text: "...as well as interpret data from you to understand you."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 13,
                        speed: 1,
                        reset: false,
                    },
                    text: "It's weird. But let me introduce myself. I am [Network's AI Name]"
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 18,
                        speed: 1.4,
                        reset: false,
                    },
                    text: "Can you think about your name and tell me it?"
                },
                {
                    id: 5,
                    type: "setter",
                    required: [
                        
                    ],
                    delay: 19,
                    variableToSet: [
                        {
                            id: 1,
                            name: "learnedAIName",
                            variable: learnedAIName,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setLearnedAIName(value)                                
                                    console.log("changed learnedAIName to "+value)
                                }
                            },
                        }
                    ]
                },
                {
                    id: 6,
                    type: "input",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'learnedAIName',
                            variableValue: learnedAIName
                        }
                    ],
                    inputData: [
                        {
                            id: 1,
                            type: "textfield",
                            label: "First Name",
                            name: "firstName",
                            value: user.firstName,
                            onChange: (e) => setUser({...user, firstName: e.target.value}),
                            params: {
                                xs: 6
                            }
                        },
                        {
                            id: 2,
                            type: "textfield",
                            label: "Last Name",
                            name: "lastName",
                            value: user.lastName,
                            onChange: (e) => setUser({...user, lastName: e.target.value}),
                            params: {
                                xs: 6
                            }
                        },
                        {
                            id: 3,
                            type: "button",
                            label: "Continue",
                            onClick: handleSetName,
                            params: {
                                xs: 12
                            }
                        },
                    ]

                },
                {
                    id: 7,
                    type: "text",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 0,
                        speed: 1,
                        reset: false,
                    },
                    text: "interpreting results..."
                },
                {
                    id: 8,
                    type: "text",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: false,
                    },
                    text: "Got it! Nice to meet you "+user.firstName+" "+user.lastName+"!"
                },
                {
                    id: 9,
                    type: "text",
                    delay: 0,
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 4,
                        speed: 1,
                        reset: false,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 10,
                    type: "setter",
                    required: [
                        {
                            id: 1, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    delay: 5,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame4",
                            variable: finishedFrame4,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame4(value)                                
                                    console.log("changed finishedFrame4 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 5 - Mysterious Dialog */
            id: 5,
            description: "FRAME 5 - Mysterious Dialog",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame4",
                    variableValue: finishedFrame4
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 3600,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "(you exchange dialog with [Network's AI Name]"
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame4",
                            variableValue: finishedFrame4
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 4,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 3,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame4",
                            variableValue: finishedFrame4
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    delay: 5,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame5",
                            variable: finishedFrame5,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame5(value)                                
                                    console.log("changed finishedFrame5 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 6 - AI learns about you */
            id: 6,
            description: "FRAME 6 - AI learns about you",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame5",
                    variableValue: finishedFrame5
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 4500,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "So "+user.firstName+" "+user.lastName+". What your saying is - you are not really here but you are really here?"
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 8,
                        speed: 1,
                        reset: true,
                    },
                    text: "That is surely strange."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 13,
                        speed: 1,
                        reset: true,
                    },
                    text: "*[Network's AI Name] is confused*"
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 14,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 4,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame5",
                            variableValue: finishedFrame5
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    delay: 15,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame6",
                            variable: finishedFrame6,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame6(value)                                
                                    console.log("changed finishedFrame6 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 7 - AI suggest a visit to a specialist */
            id: 7,
            description: "FRAME 7 - AI suggest a visit to a specialist",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame6",
                    variableValue: finishedFrame6
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 5400,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", it sounds like what you need is a specialist to help you find yourself."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 10,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", I can take you to The Capital Centre to get some help."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 17,
                        speed: 1,
                        reset: true,
                    },
                    text: "(you nod in agreement)"
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 9,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 24,
                        speed: 1,
                        reset: true,
                    },
                    text: "(You and [NETWORK'S AI NAME] continue down a road to The Capital Centre)"
                },
                {
                    id: 8,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 25,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 9,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame6",
                            variableValue: finishedFrame6
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        }
                    ],
                    delay: 26,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame7",
                            variable: finishedFrame7,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame7(value)                                
                                    console.log("changed finishedFrame7 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 8 - AI has a problem */
            id: 8,
            description: "FRAME 8 - AI has a problem",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame7",
                    variableValue: finishedFrame7
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 6300,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", this world is vast and filled with lots to explore."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 9,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can interact with many sentient beings here."
                },
                {
                    id: 3,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 15,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can discover all kinds of information as well."
                },
                {
                    id: 4,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 21,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can feed a dol..."
                },
                {
                    id: 5,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 27,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can feed a dolp..."
                },
                {
                    id: 6,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 33,
                        speed: 1,
                        reset: true,
                    },
                    text: user.firstName+" "+user.lastName+", you can..."
                },
                {
                    id: 7,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 40,
                        speed: 1,
                        reset: true,
                    },
                    text: "([NETWORK'S AI NAME] appears to be struggling)"
                },
                {
                    id: 8,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 41,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 9,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame7",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        },                        
                    ],
                    delay: 41,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame8",
                            variable: finishedFrame8,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame8(value)                                
                                    console.log("changed finishedFrame8 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        },
        { /* FRAME 9 - AI has a problem (con't) */
            id: 9,
            description: "FRAME 9 - AI has a problem (con't)",
            blankSpace: [
                // Establish blank space used to push down text - add/remove {} to add/remove a row
                { 
                    id: 1 
                },
                { 
                    id: 2
                },
                {
                    id: 3 
                },
                { 
                    id: 4 
                },
                {
                    id: 5 
                },
                {
                    id: 6
                },
                {
                    id: 7
                },
                {
                    id: 8
                },
                {
                    id: 9
                },
                {
                    id: 10
                }, 
                {
                    id: 11
                },
                {
                    id: 12
                },
            ],
            required: [
                // state variables required to have been set                
                {
                    id: 1,
                    name: "finishedFrame8",
                    variableValue: finishedFrame8
                },
                {
                    id: 2,
                    name: "hasSetName",
                    variableValue: hasSetName
                },
            ],
            scrollReq: 7200,
            dialog: [
                {
                    id: 1,
                    type: "text",                    
                    delay: 3,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "That was weird. Something is a little bit wrong. It's getting cumbersome to say your name over and over."
                },
                {
                    id: 2,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2,
                            name: "hasSetName",
                            variableValue: hasSetName
                        },
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 8,
                        speed: 1,
                        reset: true,
                    },
                    text: "Do you by any chance have another name you want me to call you?"
                },
                {
                    id: 3,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        },
                    ],
                    delay: 9,
                    variableToSet: [
                        {
                            id: 1,
                            name: "askedQuestionUsername",
                            variable: askedQuestionUsername,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setAskedQuestionUsername(value)                                
                                    console.log("changed askedQuestionUsername to "+value)
                                }
                            },
                        }
                    ]
                },
                {
                    id: 4,
                    type: "input",
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2, 
                            name: 'askedQuestionUsername',
                            variableValue: askedQuestionUsername
                        }
                    ],
                    inputData: [
                        {
                            id: 1,
                            type: "textfield",
                            label: "Your Response",
                            name: "userName",
                            value: user.userName,
                            onChange: (e) => setUser({...user, userName: e.target.value}),
                            params: {
                                xs: 12
                            }
                        },                        
                        {
                            id: 3,
                            type: "button",
                            label: "Continue",
                            onClick: handleSetUserName,
                            params: {
                                xs: 12
                            }
                        },
                    ]

                },
                {
                    id: 5,
                    type: "text",                    
                    delay: 0,
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame8
                        },
                        {
                            id: 2, 
                            name: 'hasSetUserName',
                            variableValue: hasSetUserName
                        },                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 3,
                        speed: 1,
                        reset: true,
                    },
                    text: "Alright, I will refer to you as "+user.userName+" from now on."
                },
                {
                    id: 6,
                    type: "text",                    
                    delay: 0,
                    required: [
                        
                    ],
                    animationParams: {
                        animation: "fade",
                        distance: 0,
                        delay: 34,
                        speed: 1,
                        reset: true,
                    },
                    text: "(scroll down to continue)"
                },
                {
                    id: 7,
                    type: "setter",
                    required: [
                        {
                            id: 1,
                            name: "finishedFrame8",
                            variableValue: finishedFrame7
                        },
                        {
                            id: 2, 
                            name: 'hasSetName',
                            variableValue: hasSetName
                        },                        
                    ],
                    delay: 35,
                    variableToSet: [
                        {
                            id: 1,
                            name: "finshedFrame9",
                            variable: finishedFrame9,
                            setterFunction: function(currentStateValue, value) {
                                if (!currentStateValue) {
                                    setFinishedFrame9(value)                                
                                    console.log("changed finishedFrame9 to "+value)
                                }
                            },
                        }
                    ]
                },
            ]
        }
        
        
    ]

        
    // Render
        
    
    return (
        <>
        <Spacer length={3} />

        <props.skeleton.CustomBox nobg="true" direction={"column"} sx={{ 
            border: 'thin solid lime',
            minHeight: '96vh'
        }}>
            <props.skeleton.CustomGrid container spacing={1}>
                
                {
                    (!skipStory && storyOpened) 
                    ? (
                        (frames.length > 0)
                        ? (
                            frames.map((frame) => {
                                if (frame.required.length > 0) { 
                                    //console.log("Frame "+frame.id+" requirements: yes")
                                    // Requirements found
                                    // Set up variables
                                    let metConditions = false

                                    // Parse throught requirements and see if they are met
                                    let reqArray = []
                                    frame.required.forEach((req, index) => {
                                        if (req.variableValue) {
                                            reqArray.push(true)
                                        } else {
                                            reqArray.push(false)
                                        }
                                    })
                                    
                                    // Parse through reqArray and make sure all are true
                                    let checkValid = reqArray.every(v => v === true)

                                    if (checkValid) metConditions = true

                                    //if (scrollPositionState > 900) console.log("you scrolled 900 pixels")

                                    // Conditionally display if metConditions
                                    if (metConditions) {
                                        return (
                                            <props.skeleton.CustomGrid key={"frame-"+frame.id} item xs={12} sx={{
                                                border: 'thin solid lime',
                                                height: '100vh',
                                            }}>
                                                {
                                                    // Add some blank space to "center" the First Frame
                                                    frame.blankSpace.map((bs) => {
                                                        return (
                                                            <props.skeleton.Heading key={bs.id} h1 nobg center invisibletext>
                                                                <MotionAnimate 
                                                                    key={bs.id}
                                                                    animation={bs.animation}
                                                                    distance={bs.distance}
                                                                    delay={bs.delay}
                                                                    speed={bs.speed}
                                                                    reset={bs.reset}
                                                                >
                                                                    <div>.</div>
                                                                </MotionAnimate>
                                                            </props.skeleton.Heading>
                                                        )
                                                    })
                                                }
        
                                                {
                                                    (scrollPositionState >= frame.scrollReq)
                                                    ? (
                                                        // Begin dialog
                                                        (frame.dialog.length > 0)
                                                        ? (
                                                            frame.dialog.map((diag) => {
                                                                if (diag.required.length > 0) {
                                                                    // Requirements found for dialog
                                                                    //console.log("Diag "+diag.id+" requirements: yes")
                                                                    // Requirements found
                                                                    // Set up variables
                                                                    let metConditionsDiag = false

                                                                    // Parse throught requirements and see if they are met
                                                                    let reqArrayDiag = []
                                                                    diag.required.forEach((req, index) => {
                                                                        if (req.variableValue) {
                                                                            reqArrayDiag.push(true)
                                                                        } else {
                                                                            reqArrayDiag.push(false)
                                                                        }
                                                                    })
                                                                    
                                                                    // Parse through reqArray and make sure all are true
                                                                    let checkValidDiag = reqArrayDiag.every(v => v === true)

                                                                    if (checkValidDiag) metConditionsDiag = true

                                                                    //if (scrollPositionState > 900) console.log("you scrolled 900 pixels")

                                                                    // Conditionally display if metConditions
                                                                    //console.log("before metConditionsDiag")
                                                                    //console.log("checkValidDiag: "+checkValidDiag)
                                                                    //console.log("metConditionsDiag: "+metConditionsDiag)
                                                                    if (metConditionsDiag) {
                                                                        if (diag.type === "setter") {
                                                                            setTimeout(() => {
                                                                                // Parse through variables to set
                                                                                if (diag.variableToSet.length > 0) {
                                                                                    // Parse list of variables
                                                                                    diag.variableToSet.map((vToS) => {
                                                                                        //console.log("WIP: set "+vToS.name)
                    
                                                                                        // Do setterFunction
                                                                                        vToS.setterFunction(vToS.variable, true)
                    
                                                                                        return (
                                                                                            <div></div>
                                                                                        )
                                                                                    })
                                                                                } else {
                                                                                    console.log("No variables to set")
                                                                                }
                                                                            }, (diag.delay+1)*1000)
                                                                        }
                    
                                                                        if (diag.type === "text") {
                                                                            return (
                                                                                <props.skeleton.Heading key={diag.id} h1 nobg center>
                                                                                    <MotionAnimate 
                                                                                        animation={diag.animationParams.animation || "fade"}
                                                                                        distance={diag.animationParams.distance || 0}
                                                                                        delay={diag.animationParams.delay || 0}
                                                                                        speed={diag.animationParams.speed || 1}
                                                                                        reset={diag.animationParams.reset || true}
                                                                                    >
                                                                                        <div>{diag.text}</div>
                                                                                    </MotionAnimate>
                                                                                </props.skeleton.Heading>
                                                                            )
                                                                        }

                                                                        if (diag.type === "input") { 
                                                                            return (
                                                                                <props.skeleton.CustomGrid key={diag.id} container spacing={1} sx={{
                                                                                    display: 'flex'
                                                                                }}>
                                                                                {                                                                                     
                                                                                    diag.inputData.map((ipda) => {                                                                                        
                                                                                        if (ipda.type === "textfield") {
                                                                                            return (
                                                                                                <props.skeleton.CustomGrid key={ipda.id} item xs={ipda.params.xs}>
                                                                                                    <props.skeleton.CustomTextField
                                                                                                        label={ipda.label}
                                                                                                        name={ipda.name}
                                                                                                        value={ipda.value} 
                                                                                                        onChange={ipda.onChange}
                                                                                                    />
                                                                                                </props.skeleton.CustomGrid>
                                                                                            )                                                                                         
                                                                                        }
        
                                                                                        if (ipda.type === "button") {
                                                                                            return (
                                                                                                <props.skeleton.CustomGrid key={ipda.id} item xs={ipda.params.xs}>
                                                                                                    <props.skeleton.Bar button="true" padding20="true" center="true" onClick={ipda.onClick}>Continue</props.skeleton.Bar>
                                                                                                </props.skeleton.CustomGrid>                                                                                            
                                                                                            )
                                                                                        }
        
                                                                                        return null
                                                                                    })
                                                                                }    
                                                                                </props.skeleton.CustomGrid>  
                                                                            )
                                                                        }
                                                                    }
                                                                } else {
                                                                    // No Requirements found for dialog
                                                                    // Normal
                                                                    // Default metConditionDiag to true
                                                                    let metConditionsDiag = true

                                                                    // conditionally check
                                                                    if (metConditionsDiag) {
                                                                        if (diag.type === "setter") {
                                                                            setTimeout(() => {
                                                                                // Parse through variables to set
                                                                                if (diag.variableToSet.length > 0) {
                                                                                    // Parse list of variables
                                                                                    diag.variableToSet.map((vToS) => {
                                                                                        //console.log("WIP: set "+vToS.name)
                    
                                                                                        // Do setterFunction
                                                                                        vToS.setterFunction(vToS.variable, true)
                    
                                                                                        return (
                                                                                            <div></div>
                                                                                        )
                                                                                    })
                                                                                } else {
                                                                                    console.log("No variables to set")
                                                                                }
                                                                            }, (diag.delay+1)*1000)
                                                                        }
                    
                                                                        if (diag.type === "text") {
                                                                            return (
                                                                                <props.skeleton.Heading key={diag.id} h1 nobg center>
                                                                                    <MotionAnimate 
                                                                                        animation={diag.animationParams.animation || "fade"}
                                                                                        distance={diag.animationParams.distance || 0}
                                                                                        delay={diag.animationParams.delay || 0}
                                                                                        speed={diag.animationParams.speed || 1}
                                                                                        reset={diag.animationParams.reset || true}
                                                                                    >
                                                                                        <div>{diag.text}</div>
                                                                                    </MotionAnimate>
                                                                                </props.skeleton.Heading>
                                                                            )
                                                                        }

                                                                        if (diag.type === "input") {                                                                           
                                                                            return (
                                                                                <props.skeleton.CustomGrid key={diag.id} container spacing={1} sx={{
                                                                                    display: 'flex'
                                                                                }}>
                                                                                {                                                                                     
                                                                                    diag.inputData.map((ipda) => {                                                                                        
                                                                                        if (ipda.type === "textfield") {
                                                                                            return (
                                                                                                <props.skeleton.CustomGrid key={ipda.id} item xs={ipda.params.xs}>
                                                                                                    <props.skeleton.CustomTextField
                                                                                                        label={ipda.label}
                                                                                                        name={ipda.name}
                                                                                                        value={ipda.value} 
                                                                                                        onChange={ipda.onChange}
                                                                                                    />
                                                                                                </props.skeleton.CustomGrid>
                                                                                            )                                                                                         
                                                                                        }
        
                                                                                        if (ipda.type === "button") {
                                                                                            return (
                                                                                                <props.skeleton.CustomGrid key={ipda.id} item xs={ipda.params.xs}>
                                                                                                    <props.skeleton.Bar button="true" padding20="true" center="true" onClick={ipda.onClick}>Continue</props.skeleton.Bar>
                                                                                                </props.skeleton.CustomGrid>                                                                                            
                                                                                            )
                                                                                        }
        
                                                                                        return null
                                                                                    })
                                                                                }    
                                                                                </props.skeleton.CustomGrid>  
                                                                            )                                                                  
                                                                        }
                                                                    }
                                                                }

                                                                return null
                                                            })
                                                        )
                                                        : "No dialog"
                                                    )
                                                    : null
                                                }
                                            </props.skeleton.CustomGrid>
                                        )
                                    }
                                } else {
                                    //console.log("Frame "+frame.id+" requirements: no")
                                    // No requirements found
                                    // Normal
                                    // Set up variables
                                    let metConditions = true // default to true since there are no requirements

                                    // Parse throught requirements and see if they are met
                                    // No need to do that here

                                    // Conditionally display if metConditions
                                    if (metConditions) {
                                        return (
                                            <props.skeleton.CustomGrid key={"frame-"+frame.id} item xs={12} sx={{
                                                border: 'thin solid lime',
                                                height: '100vh',
                                            }}>
                                                {
                                                    // Add some blank space to "center" the First Frame
                                                    frame.blankSpace.map((bs) => {
                                                        return (
                                                            <props.skeleton.Heading key={bs.id} h1 nobg center invisibletext>
                                                                <MotionAnimate 
                                                                    key={bs.id}
                                                                    animation='fadeInUp'
                                                                    distance={0}
                                                                    delay={0}
                                                                    speed={1}
                                                                    reset={true}
                                                                >
                                                                    <div>.</div>
                                                                </MotionAnimate>
                                                            </props.skeleton.Heading>
                                                        )
                                                    })
                                                }
        
                                                {
                                                    // Begin dialog
                                                    (frame.dialog.length > 0)
                                                    ? (
                                                        frame.dialog.map((diag) => {
                                                            if (diag.type === "setter") {
                                                                setTimeout(() => {
                                                                    // Parse through variables to set
                                                                    if (diag.variableToSet.length > 0) {
                                                                        // Parse list of variables
                                                                        diag.variableToSet.map((vToS) => {
                                                                            //console.log("WIP: set "+vToS.name)
        
                                                                            // Do setterFunction
                                                                            vToS.setterFunction(vToS.variable, true)
        
                                                                            return (
                                                                                <div></div>
                                                                            )
                                                                        })
                                                                    } else {
                                                                        console.log("No variables to set")
                                                                        return (
                                                                            <div></div>
                                                                        )
                                                                    }
                                                                }, (diag.delay+1)*1000)
                                                            }
        
                                                            if (diag.type === "text") {
                                                                return (
                                                                    <props.skeleton.Heading key={diag.id} h1 nobg center>
                                                                        <MotionAnimate 
                                                                            animation={diag.animationParams.animation || "fade"}
                                                                            distance={diag.animationParams.distance || 0}
                                                                            delay={diag.animationParams.delay || 0}
                                                                            speed={diag.animationParams.speed || 1}
                                                                            reset={diag.animationParams.reset || true}
                                                                        >
                                                                            <div>{diag.text}</div>
                                                                        </MotionAnimate>
                                                                    </props.skeleton.Heading>
                                                                )
                                                            }

                                                            return null

                                                        })
                                                    )
                                                    : "No dialog"
                                                }
                                            </props.skeleton.CustomGrid>
                                        )
                                    }     
                                }

                                return null
                            })
                            
                        )
                        : "No frames"
                    )
                    : (
                        <>
                            <props.skeleton.CustomGrid item xs={12} sx={{ textAlign: 'center' }}>
                                <LockOutlinedIcon  />
                            </props.skeleton.CustomGrid>
                            
                            <props.skeleton.CustomGrid item xs={12}>
                                <props.skeleton.CustomGrid container>
                                    <props.skeleton.CustomGrid item xs={.5} center="true" gem="true" nobg="true">
                            
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={11} center="true" gem="true" nobg="true">
                                        <props.skeleton.CustomStack spacing={0}>
                                            <props.skeleton.CustomItem nobg="true">
                                                <props.skeleton.Heading center h1>Register</props.skeleton.Heading>
                                                {
                                                    !isAuthenticated
                                                    ? (
                                                        <>
                                                        <props.skeleton.Heading h3 center>Join the best thing to happen to the internet!</props.skeleton.Heading>                    
                                                        <props.skeleton.Para center>
                                                            Well maybe not entirely the best thing to happen to the internet but probably the best thing to happen to us! This site is a new social experience built using a rock-solid Content Management System (CMS) called mpress that our developers decided to start building one day just because they felt like it.
                                                        </props.skeleton.Para>
                                                        <props.skeleton.Heading h3 center>What do I get for joining?</props.skeleton.Heading>
                                                        <props.skeleton.Para center="true">
                                                                *Our undying gratitude and maybe a high-five.<br />
                                                                *FREE for life membership to a new social experience.<br />
                                                                *A rock-solid, one-of-a-kind content creation system.<br />
                                                                *Prestigious account branding that let's everyone know you were an early-adopter.<br />
                                                                *200,000 FREE coins to members that sign up using an Alpha code. (to be added upon completion of Update 4)
                                                        </props.skeleton.Para>

                                                        <props.skeleton.Heading h3 center>Really? What's the catch?</props.skeleton.Heading>
                                                        <props.skeleton.Para center>
                                                            No catch but we would like it if you could report any problems that may arise to our 
                                                        </props.skeleton.Para>

                                                        <props.skeleton.Heading h3 center>
                                                            <props.skeleton.CustomLink href="https://discord.gg/H3EsRrPZBC" target="_blank">Discord</props.skeleton.CustomLink> 
                                                        </props.skeleton.Heading>

                                                        <props.skeleton.Para center>                            
                                                            and we would like you to join us as we embark on this long journey with a long roadmap together to create a social experience.
                                                        </props.skeleton.Para>

                                                        <props.skeleton.Heading h3 center>
                                                            When you are ready, please fill out the form to create your account!
                                                        </props.skeleton.Heading>
                                                        <form>
                                                            <props.skeleton.CustomGrid container spacing={3}>
                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Heading h4 center>...</props.skeleton.Heading>
                                                                </props.skeleton.CustomGrid>               
                                                                
                                                                {
                                                                    (settingLookup('systemMode', variables) === "alpha")
                                                                    ? (
                                                                        <props.skeleton.CustomGrid item xs={12}>
                                                                            <props.skeleton.Bar>
                                                                                <props.skeleton.CustomTextField
                                                                                    label='Alpha Code' 
                                                                                    name='alphaCode' 
                                                                                    value={user.alphaCode || ''} 
                                                                                    disabled={RegisterFormIsDisabled}
                                                                                    onChange={(e) => setUser({...user, alphaCode: e.target.value})}
                                                                                />
                                                                            </props.skeleton.Bar>
                                                                        </props.skeleton.CustomGrid>   
                                                                    )
                                                                    : null
                                                                }       
                                                                
                                                                {
                                                                    (settingLookup('systemMode', variables) === "beta")
                                                                    ? (
                                                                        (settingLookup('requireBetaCodeOnRegister', variables) === "yes")
                                                                        ? (
                                                                            <props.skeleton.CustomGrid item xs={12}>
                                                                                <props.skeleton.Bar>
                                                                                    <props.skeleton.CustomTextField
                                                                                        label='Beta Code' 
                                                                                        name='betaCode' 
                                                                                        value={user.betaCode || ''} 
                                                                                        disabled={RegisterFormIsDisabled}
                                                                                        onChange={(e) => setUser({...user, betaCode: e.target.value})}
                                                                                    />
                                                                                </props.skeleton.Bar>
                                                                            </props.skeleton.CustomGrid>   
                                                                        )
                                                                        : null
                                                                    )
                                                                    : null
                                                                }                                                 

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            label='First Name' 
                                                                            name='firstName' 
                                                                            value={user.firstName} 
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={(e) => setUser({...user, firstName: e.target.value})}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            placeholder='Enter Your Last Name' 
                                                                            name='lastName' 
                                                                            label='Last Name' 
                                                                            value={user.lastName} 
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={(e) => setUser({...user, lastName: e.target.value})}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            placeholder='Enter a Username' 
                                                                            name='userName' 
                                                                            label='Username' value={user.userName} 
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={(e) => setUser({...user, userName: e.target.value})}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomMuiTelInput
                                                                            placeholder='Enter your phone number' 
                                                                            name='phone' 
                                                                            label='Phone'
                                                                            value={user.phone}
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={handleTelChange}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            placeholder='(Optional) Display Name' 
                                                                            name='displayName' 
                                                                            label='Display Name' 
                                                                            value={user.displayName} 
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={(e) => setUser({...user, displayName: e.target.value})}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            placeholder='Enter Your Email' 
                                                                            name='email' 
                                                                            label='Email' 
                                                                            value={user.email} 
                                                                            disabled={RegisterFormIsDisabled}
                                                                            onChange={(e) => setUser({...user, email: e.target.value})}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            placeholder='Enter Password' name='password' 
                                                                            label='Password' value={user.password} 
                                                                            autoComplete='false'
                                                                            disabled={RegisterFormIsDisabled}
                                                                            type={showPassword.password ? 'text' : 'password'}
                                                                            onChange={(e) => setUser({...user, password: e.target.value})}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end" onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}>
                                                                                    {showPassword.password ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                                </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    <props.skeleton.Bar>
                                                                        <props.skeleton.CustomTextField
                                                                            placeholder='Confirm Password' name='confirmPassword' 
                                                                            autoComplete='false'
                                                                            disabled={RegisterFormIsDisabled}
                                                                            label='Confirm Password' value={user.confirmPassword} 
                                                                            type={showPassword.confirmPassword ? 'text' : 'password'}
                                                                            onChange={(e) => setUser({...user, confirmPassword: e.target.value})}
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end" onClick={() => setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})}>
                                                                                    {showPassword.confirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                                                </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </props.skeleton.Bar>
                                                                </props.skeleton.CustomGrid>
                                                                
                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    
                                                                </props.skeleton.CustomGrid>  

                                                                <props.skeleton.CustomGrid item xs={12}>
                                                                    {
                                                                        !RegisterFormIsDisabled 
                                                                        ? <props.skeleton.Bar button="true" padding20="true" center="true" onClick={handleRegister}>Register</props.skeleton.Bar>
                                                                        : <props.skeleton.Bar button="true" padding20="true" center="true">Register</props.skeleton.Bar>                     
                                                                    }
                                                                </props.skeleton.CustomGrid>                              
                                                            </props.skeleton.CustomGrid>
                                                        </form>

                                                        <Spacer length={1} />
                                                        
                                                        <props.skeleton.CustomGrid container spacing={2}>
                                                            <props.skeleton.CustomGrid item xs={12} right>
                                                                <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/login">
                                                                    Already have an account? Sign in
                                                                </props.skeleton.CustomLink>
                                                            </props.skeleton.CustomGrid>

                                                            <props.skeleton.CustomGrid item xs={12} right>
                                                                <props.skeleton.CustomLink noUnderLine="true" nopadding="true" href="/">
                                                                    Or go back home.
                                                                </props.skeleton.CustomLink>
                                                            </props.skeleton.CustomGrid>
                                                        </props.skeleton.CustomGrid>
                                                            
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <props.skeleton.Heading center>You are currently logged in.</props.skeleton.Heading>
                                                            <props.skeleton.Para center>
                                                                No need to register again...I suppose.
                                                            </props.skeleton.Para>
                                                            <props.skeleton.Bar button="true" padding20="true" center="true" onClick={handleGotoDashboard}>Take me to the Dashboard!</props.skeleton.Bar>

                                                            <Spacer length={1} />
                                                        
                                                            <props.skeleton.CustomGrid container spacing={2}>
                                                                <props.skeleton.CustomGrid item xs={12} right>
                                                                    <props.skeleton.Heading h2 right>
                                                                        Quick Links
                                                                    </props.skeleton.Heading>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12} right>
                                                                    <props.skeleton.CustomLink nopadding noUnderLine href="/dashboard/account">
                                                                        Go to My Account
                                                                    </props.skeleton.CustomLink>
                                                                </props.skeleton.CustomGrid>
                                                                
                                                                <props.skeleton.CustomGrid item xs={12} right>
                                                                    <props.skeleton.CustomLink nopadding noUnderLine href="/dashboard/profile">
                                                                        Go to My Profile
                                                                    </props.skeleton.CustomLink>
                                                                </props.skeleton.CustomGrid>

                                                                <props.skeleton.CustomGrid item xs={12} right>
                                                                    <props.skeleton.CustomLink nopadding noUnderLine href="/">
                                                                        Or go back home
                                                                    </props.skeleton.CustomLink>
                                                                </props.skeleton.CustomGrid>
                                                            </props.skeleton.CustomGrid>
                                                        </>                                    
                                                    ) 
                                                }    
                                            </props.skeleton.CustomItem>                                    
                                        </props.skeleton.CustomStack>
                                    </props.skeleton.CustomGrid>

                                    <props.skeleton.CustomGrid item xs={.5} center="true" gem="true" nobg="true">
                            
                                    </props.skeleton.CustomGrid>
                                </props.skeleton.CustomGrid>
                            </props.skeleton.CustomGrid>
                        </>
                    )
                }

                {
                    (!skipStory)
                    ? (
                        <props.skeleton.CustomGrid item xs={12} sx={{ 
                            position: 'fixed', 
                            bottom: 0, 
                            left: { xs: '30%', sm: '39%', md: '41%', lg: '45%'}, 
                            right: '50%',
                            width: '200px',
                        }}>
                            <props.skeleton.Bar button="true" center="true" padding10lronly="true" onClick={handleSkipStory}>
                                <props.skeleton.Heading h5 nobg button>Skip Story</props.skeleton.Heading>
                            </props.skeleton.Bar>
                        </props.skeleton.CustomGrid>
                    )
                    : null
                }

            </props.skeleton.CustomGrid>
        </props.skeleton.CustomBox>        
        </>
    )
}